import { db } from "../firebase.config"
import { useState, useEffect } from "react"
import {
  collection,
  onSnapshot,
  addDoc, serverTimestamp, query, where
} from "firebase/firestore"
import './Event.css'




function Event({bar, setBar}) {
  const [events, setEvents] = useState([])


  const eventsCollectionRef = collection(db, "events")
  const q = query(eventsCollectionRef);


  useEffect(() => {
    window.scrollTo(0, 0)
    setBar(true)
    setTimeout(() => {
      setBar(false)
    }, 2000)
    // updated on changes to firestore without page reload
    onSnapshot(q, snapshot => {
      setEvents(snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      }))
    })
  }, [])

  
  return (
    <div className="Placement">

    <div className="consultants" id="counsels">
      { events.map((event, i) => (
        <div className="consultant" key={event.id} id="consultant">

          <p className="field subtitle">{ event.name } <br/> <span className="current">{ event.type }</span></p>
          <img className="dp_event"  src={event.dp} />
          <p className="field_subtitle_event" id="gap">{event.description}</p>
          <p className="field_expertise" id="gap">Estimated Schedule: <div class="colored_text">{event.schedule}</div></p>
          <p className="field_expertise" >Mentors:</p>
          <p className="field" class="container_mentors" id="gap">
          {console.log(event.consultants)}
          {event.consultants.map((consultant) => (
            <div key={consultant.id} class="buttonstyleUImentors">
                <div class="font_UI">
                <a href={`https://sateek.co/profile/${consultant.id}`} class="UIformentorlinks" target="_blank" rel="noopener noreferrer" >
                <center>{consultant.name}</center>
                </a>
                </div>
                
            </div>
            ))}</p>
          <p className="field_expertise">{ event.points.join(", ") }</p>
          <div className="buttons">
            <button class="custom-button" id="book_button"><a href={event.form} class="UIformentorlinks" target="_blank">Registration Form</a></button>
          </div>

          
        </div>
      ))}
    </div>
     
    </div>

  );
}

export default Event;