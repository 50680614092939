import './Resources.css'
import {useEffect } from "react"

function Resources() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Resources">
      Hello
    </div>
  );
}
export default Resources;
