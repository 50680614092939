import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBM7reRrYD7p8_YJqJjMuASIEdjzclduiY",
    authDomain: "consultationdojo.firebaseapp.com",
    projectId: "consultationdojo",
    storageBucket: "consultationdojo.appspot.com",
    messagingSenderId: "726877484951",
    appId: "1:726877484951:web:48a093c3538b734992b6d2",
    measurementId: "G-NQ2VLPCSGY"
  };
  
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)

export { db }