import { db } from "../firebase.config"
import emailjs from "@emailjs/browser";
import { useState, useEffect } from "react"
import {
  collection,
  onSnapshot,
  addDoc, serverTimestamp, query, where
} from "firebase/firestore"
import './Group.css';



function Group({bar, setBar}) {
  const [groups, setGroups] = useState([])

  // form to add request for a consulatation
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "", 
    gId: "", 
    gTopic: "",
    interests: "",
    language: "",
    isComplete: false
  })



  // when active displays request form and disables other things
  const [popupActive, setPopupActive] = useState(false)
  const [alertActive, setAlertActive] = useState(false)

  const groupsCollectionRef = collection(db, "groups")
  const formsCollectionRef = collection(db, "groupF")


  useEffect(() => {

    emailjs.init("rJMlxBt9qtMJrKoYl")
    window.scrollTo(0, 0)
    setBar(true)
    setTimeout(() => {
      setBar(false)
    }, 2000)
    // updated on changes to firestore without page reload
    onSnapshot(groupsCollectionRef, snapshot => {
      setGroups(snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      }))
    })
  }, [])

  const sendEmail = async (n, m, mail) => {
    const serviceId = "service_cvkbd7h";
    const templateId = "template_axec606";
    try {
     
      await emailjs.send(serviceId, templateId, {
       name: n,
        mentor: m,
        email:mail
      });
    } catch (error) {
      console.log(error);
    } 
  };


  // when the user clicks submit on the form
  // checks, sends request and then empties the form for futher processing
  const handleSubmit = e => {
    e.preventDefault()

    if (
      !form.name ||
      !form.email ||
      !form.interests ||
      !form.phone
    ) {
      alert("Please fill out required fields")
      return
    }

    addDoc(formsCollectionRef, {...form, timestamp: serverTimestamp()})



    // sendEmail(form.name, form.cName, form.email)
    window.scrollTo(0, 0)
    setAlertActive(true)
    setTimeout(() => {
    setAlertActive(false)
    }, 5000)
  
   

    setForm({
        name: "",
        email: "",
        phone: "", 
        gId: "", 
        gTopic: "",
        interests: "",
        language: "",
        isComplete: false
    })
    setPopupActive(false)
  }

  // when user clicks close button on the form
  const handleClose = () => {
    setForm({
        name: "",
        email: "",
        phone: "", 
        gId: "", 
        gTopic: "",
        interests: "",
        language: "",
        isComplete: false
    })
    setPopupActive(false)
  }

  // displays the form and pre fills some information
  const handleView = (id, topic) => {
    setPopupActive(true)
    setForm({...form, gId: id, gTopic: topic})
  }

  return (
    <div className="Group">

    {alertActive && <div class="alert alert-success align" role="alert">
        Group Discussion Booked! We will let you as soon as the meeting is confirmed
      </div>}

      <div className="info center">
                <span className="bottom_border"><h2 id="jumbo_h2">Introducing Group Discussions</h2></span>
                <ul id="jumbo_list">
                <li class="jumbo_list_element margin_top">Select from our curate topics </li>
                <li class="jumbo_list_element">Register by telling us what you want to talk about</li>
                <li >We will setup the meet the with likeminded people where you can learn by sharing</li>
                </ul>
        </div>


  
    <div className="groups" id="counsels">
      { groups.map((group, i) => (
        <div className="group" key={group.id} id="group">
          
          <img className="img"  src={group.poster}/>

          <p className="topic"><strong>{ group.topic } </strong></p>
          <p className="capacity">Capacity : { group.capacity } </p>
          <p className="timing">Timing : <span className="time">{ group.timing }</span> </p>
          <p className="points">
          <strong>Sample Discussion Subtopics</strong>
          {
            group.discussion.map((point, k) => (<p className="subtopics">{point} </p>))
          }  
          </p>
          <p className="note tohide_phone">Note:{ group.grouping }* </p>

          

          <div className="buttons_group">
            <button class="custom-button_group" id="book_button_group" onClick={() => handleView(group.id, group.topic)}>Participate</button>
          </div>
        </div>
      ))}
    </div>

    { popupActive && <div className="popup">
      <div className="popup-inner">
        <h3 id="form_topic">Participate in {form.gTopic}</h3>

        <form onSubmit={handleSubmit}>

          <div className="form-container">
            <label>Name*</label>
            <input 
              type="text" 
              value={form.name} 
              onChange={e => setForm({...form, name: e.target.value})} />
          </div>

          <div className="form-container">
            <label>Email*</label>
            <input 
              type="text" 
              value={form.email} 
              onChange={e => setForm({...form, email: e.target.value})} />
          </div>


          <div className="form-container">
            <label>Phone*</label>
            <input 
              type="text" 
              value={form.phone} 
              onChange={e => setForm({...form, phone: e.target.value})} />
          </div>

          <div className="form-container">
            <label>What are you interested in talking about?*(We will group you based on the information you provide in the form and pair you with people with similar interests, please be a bit specific.)</label>
            <input 
              type="text" 
              value={form.interests} 
              onChange={e => setForm({...form, interests: e.target.value})} />
          </div>


          <div className="form-container">
            <label>Preferred Language</label>
            <input 
              type="text" 
              value={form.language} 
              onChange={e => setForm({...form, language: e.target.value})} />
          </div>

         

          <div className="buttons">
            <button type="submit">Submit</button>
            <button type="button" class="close_button" onClick={handleClose}>Close</button>
          </div>

        </form>
      </div>
    </div>}


     
    </div>
  );
}

export default Group;